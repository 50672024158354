<script>
export default {
    data() {
        const today = new Date();
        const currentDate = today.toISOString().split('T')[0];
        const startDate = new Date(today.getFullYear(), today.getMonth(), 2);
        const startDateString = startDate.toISOString().split('T')[0];

        return {
            startDateMenu: false,
            endDateMenu: false,
            startDate: startDateString,
            endDate: currentDate,
            maxDate: currentDate,
        };
    },
    computed: {
        minStartDate() {
            const endDate = new Date(this.endDate);
            const minStartDate = new Date(endDate.getTime() - 30 * 24 * 60 * 60 * 1000);
            return minStartDate.toISOString().split('T')[0];
        },
        minEndDate() {
            return this.startDate || this.maxDate;
        },
        maxEndDate() {
            const startDate = new Date(this.startDate);
            const maxEndDate = new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000);
            const today = new Date();
            return maxEndDate > today ? today.toISOString().split('T')[0] : maxEndDate.toISOString().split('T')[0];
        },
        formattedDateRange() {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            const start = new Date(this.startDate).toLocaleDateString('id-ID', options);
            const end = new Date(this.endDate).toLocaleDateString('id-ID', options);
            return `${start} - ${end}`;
        },
    },
    watch: {
        startDate(newStartDate) {
            if (new Date(this.endDate) < new Date(newStartDate)) {
                this.endDate = newStartDate; // Perbarui endDate jika kurang dari startDate
            }
            this.emitDateUpdates();
        },
        endDate() {
            this.emitDateUpdates();
        },
    },
    methods: {
        emitDateUpdates() {
            this.$emit('update:startDate', this.startDate);
            this.$emit('update:endDate', this.endDate);
            this.$emit('update:formattedDateRange', this.formattedDateRange);
        },
    },
    mounted() {
        // Emit default values on mount
        this.emitDateUpdates();
    },
};
</script>

<template>
    <v-row no-gutters justify="end">
        <v-col cols="6">
            <v-sheet class="px-2">
                <v-menu v-model="startDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    max-width="auto" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="pa-0 ma-0 rounded-xl" :outlined="true" hide-details v-model="startDate"
                            readonly v-bind="attrs" v-on="on">
                            <template v-slot:append>
                                <v-icon @click="startDateMenu = true" class="cursor-pointer">mdi-calendar</v-icon>
                            </template>
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="startDate" :min="minStartDate" :max="maxDate" @input="startDateMenu = false">
                    </v-date-picker>
                </v-menu>
            </v-sheet>
        </v-col>
        <v-col cols="6">
            <v-sheet class="px-2">
                <v-menu v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    max-width="auto" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="pa-0 ma-0 rounded-xl" :outlined="true" hide-details v-model="endDate"
                            readonly v-bind="attrs" v-on="on">
                            <template v-slot:append>
                                <v-icon @click="endDateMenu = true" class="cursor-pointer">mdi-calendar</v-icon>
                            </template>
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="endDate" :min="minEndDate" :max="maxEndDate" @input="endDateMenu = false">
                    </v-date-picker>
                </v-menu>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<style scoped>
.v-text-field--outlined>>>fieldset {
    border-color: #ABD1C6;
}
</style>