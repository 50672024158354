<script>
import DateFilterComponent from '@/components/LayananTransaksi/DateFilterComponent.vue';
import ReportSummaryComponent from '@/components/LayananTransaksi/VLL/ReportSummaryComponent.vue';
import RevenueGraphComponent from '@/components/LayananTransaksi/VLL/RevenueGraphComponent.vue';
import TableStyleComponentTotal from '@/components/LayananTransaksi/VLL/TableStyleComponentTotal.vue';
import TableStyleComponentDetail from '@/components/LayananTransaksi/VLL/TableStyleComponentDetail.vue';

export default {
    components: {
        DateFilterComponent,
        ReportSummaryComponent,
        RevenueGraphComponent,
        TableStyleComponentTotal,
        TableStyleComponentDetail,
    },
    data() {
        return {
            description: 'TVL',
            start_date: '',
            end_date: '',
            formatted_date_range: '',
            selectedPeriod: '',
            selectedGate: [],
            selectedGolongan: [],
            reportData: {
                summaryData: null,
                chartData: null,
                detailsData: null
            },
            fetchDebounceTimeout: null,
        };
    },
    computed: {
        formattedGate() {
            return this.selectedGate.join(",");
        },
        formattedGolongan() {
            return this.selectedGolongan.join(",");
        },
        fetchParamsSummary() {
            return {
                from: this.start_date,
                to: this.end_date,
            };
        },
        fetchParamsChart() {
            return {
                from: this.start_date,
                to: this.end_date,
                gate_ids: '',
                // gate_ids: this.formattedGate,
            };
        },
        fetchParamsDetail() {
            return {
                from: this.start_date,
                to: this.end_date,
                gate_ids: '',
                // gate_ids: this.formattedGate,
            };
        },
    },
    watch: {
        start_date: "handleParameterChange",
        end_date: "handleParameterChange",
        selectedGate: "handleParameterChange",
    },
    methods: {
        handleParameterChange() {
            this.debouncedFetchData(() => {
                this.fetchDataSummary();
                this.fetchDataChart();
                this.fetchDataDetail();
            });
        },
        updateStartDate(newDate) {
            this.start_date = newDate;
        },
        updateEndDate(newDate) {
            this.end_date = newDate;
        },
        updateFormattedDateRange(newRange) {
            this.formatted_date_range = newRange;
        },
        updatePeriod(newPeriod) {
            this.selectedPeriod = newPeriod;
        },
        updateGate(newGate) {
            this.selectedGate = newGate;
        },
        updateGolongan(newGolongan) {
            this.selectedGolongan = newGolongan;
        },
        debouncedFetchData(callback) {
            clearTimeout(this.fetchDebounceTimeout);
            this.fetchDebounceTimeout = setTimeout(() => {
                callback();
            }, 300);
        },
        async fetchDataSummary() {
            try {
                const response = await this.$axios.get('vll-summaries', {
                    params: this.fetchParamsSummary
                });
                this.reportData.summaryData = response.data.data;
            } catch (error) {
                console.error('Error fetching summary data:', error);
            }
        },
        async fetchDataChart() {
            try {
                const response = await this.$axios.get('vll-charts', {
                    params: this.fetchParamsChart
                });
                this.reportData.chartData = response.data.data;
            } catch (error) {
                console.error('Error fetching chart data:', error);
            }
        },
        async fetchDataDetail() {
            try {
                const response = await this.$axios.get('vll-details', {
                    params: this.fetchParamsDetail
                });
                this.reportData.detailsData = response.data.data[0];
            } catch (error) {
                console.error('Error fetching detail data:', error);
            }
        },
    },
    mounted() {
        this.handleParameterChange();
    },
    beforeDestroy() {
        clearTimeout(this.fetchDebounceTimeout);
    }
};
</script>

<template>
    <div class="pa-0 ma-0">
        <div class="pb-3 pt-4 px-10">
            <v-row no-gutters>
                <v-col align="left" class="my-auto" cols="7">
                    <v-sheet>
                        <h3 class="text--black" style="font-size: 20px; font-weight: 500">{{ formatted_date_range }}
                        </h3>
                    </v-sheet>
                </v-col>
                <v-col align="right" class="my-auto" cols="5">
                    <v-sheet>
                        <v-row no-gutters>
                            <v-col align="left" class="my-auto" cols="2">
                                <v-sheet>
                                    <span class="text--black text-uppercase"
                                        style="font-size: 12px; font-weight: 400; color: #BBBBBB">Filter</span>
                                    <h6 class="text--black" style="font-size: 14px; font-weight: 400">Pilih Tanggal
                                    </h6>
                                </v-sheet>
                            </v-col>
                            <v-col align="right" class="my-auto" cols="10">
                                <v-sheet>
                                    <DateFilterComponent @update:startDate="updateStartDate"
                                        @update:endDate="updateEndDate"
                                        @update:formattedDateRange="updateFormattedDateRange" />
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
        </div>
        <ReportSummaryComponent v-if="reportData && reportData.summaryData" :data="reportData.summaryData" /> 
        <RevenueGraphComponent v-if="reportData && reportData.chartData" :data="reportData.chartData" @update:selectedPeriod="updatePeriod" @update:selectedGate="updateGate" @update:selectedGolongan="updateGolongan" /> 
        <div class="pb-3 pt-4 px-10">
            <!-- Detail Section -->
            <div class="mb-5 rounded-xl py-4 px-6 text-center bordered">
                <h3 style="font-size: 18px; font-weight: 500;" class="text-uppercase">Detail</h3>
            </div>
            <div class="py-5">
                <v-row class="mb-4">
                    <v-col cols="9" class="my-auto">
                      <h3 class="text--black" style="font-size: 20px; font-weight: 500">{{ formatted_date_range }}</h3>
                    </v-col>
                </v-row>
                <TableStyleComponentTotal :description="description" v-if="reportData && reportData.detailsData?.total_all_tvl" :data="reportData.detailsData?.total_all_tvl"  />
                <div v-if="reportData && reportData.detailsData?.detail_per_gates">
                    <template v-for="(item, index) in reportData.detailsData?.detail_per_gates">
                        <div :key="item.gate">
                            <TableStyleComponentDetail :data="item" :isEven="index % 2 === 0"  />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.bordered {
    background-color: #DAE7FF;
    border-left: 4px solid #497BDD;
    border-right: 4px solid #497BDD;
}
</style>