<template>
  <h1 style="font-size: 24px; font-weight: 600">{{ title }}</h1>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style></style>
