import Vue from "vue";
import Router from "vue-router";
import MainPage from "../pages/MainPage.vue";
import KecelakaanPage from "../pages/KecelakaanPage.vue";
import KejadianPage from "../pages/KejadianPage.vue";
import GangguanAsetPage from "../pages/GangguanAsetPage.vue";
import GangguanLaluLintasPage from "../pages/GangguanLaluLintasPage.vue";
import LaporanAsetPage from "../pages/LaporanAsetPage.vue";
import ListAsetTanahPage from "../pages/ListAsetTanahPage";
import RiwayatAsetTanahPage from "../pages/RiwayatAsetTanahPage";
import AsetTanahDetail from "../pages/AsetTanahDetail";
import PemeliharaanAsetPage from "../pages/PemeliharaanAsetPage.vue";
import InisiasiLaporanPage from "../pages/InisiasiLaporanPage.vue";
import ListAsetDihibahkanPage from "../pages/ListAsetDihibahkanPage.vue";
import ListAsetDilelangPage from "../pages/ListAsetDilelangPage.vue";
import ListAsetDimusnahkanPage from "../pages/ListAsetDimusnahkanPage.vue";
import ListAsetDiputihkanPage from "../pages/ListAsetDiputihkanPage.vue";
import ListAsetKerjasamaPage from "../pages/ListAsetKerjasamaPage.vue";
import ListAsetDitingkatkanPage from "../pages/ListAsetDitingkatkanPage.vue";
import TrafficDashboardPage from "../pages/TrafficDashboardPage.vue";
import AssetDashboardPage from "../pages/AssetDashboardPage.vue";
import LoginPage from "../pages/LoginPage";
import LayananPemeliharaanPage from "../pages/LayananPemeliharaanPage.vue";
import DetailKecelakaanPage from "../pages/DetailKecelakaanPage";
import GedungBangunanPage from "../pages/GedungBangunanPage.vue";
import DetailGedungBangunanPage from "../pages/DetailGedungBangunanPage.vue";
import RiwayatGedungBangunanPage from "../pages/RiwayatGedungBangunanPage.vue";
import JalanIrigasiJaringanPage from "../pages/JalanIrigasiJaringanPage.vue";
import DetailJalanIrigasiJaringanPage from "../pages/DetailJalanIrigasiJaringanPage.vue";
import RiwayatJalanIrigasiJaringanPage from "../pages/RiwayatJalanIrigasiJaringanPage.vue";
import AsetTakBerwujudPage from "../pages/AsetTakBerwujudPage.vue";
import DetailAsetTakBerwujudPage from "../pages/DetailAsetTakBerwujudPage.vue";
import RiwayatAsetTakBerwujudPage from "../pages/RiwayatAsetTakBerwujudPage.vue";
import RekategorisasiAset from "../pages/RekategorisasiAset.vue";
import MesinDanPeralatanPage from "../pages/MesinDanPeralatanPage.vue";
import DetailMesinDanPeralatanPage from "../pages/DetailMesinDanPeralatanPage.vue";
import RiwayatMesinDanPeralatanPage from "../pages/RiwayatMesinDanPeralatanPage.vue";
import DetailLaporanFinal from "@/pages/DetailLaporanFinal";
import DetailGangguanAsetPage from "@/pages/DetailGangguanAsetPage";
import DetailKejadianPage from "@/pages/DetailKejadianPage";
import DetailGangguanLaluLintasPage from "@/pages/DetailGangguanLaluLintasPage";
import ListKendaraanDerekPage from "@/pages/ListKendaraanDerekPage";
import CreateAssetPage from "@/pages/CreateAssetPage";
import DetailLaporanTindakLanjut from "@/pages/DetailLaporanTindakLanjut";
import DetailLaporanInisiasi from "@/pages/DetailLaporanInisiasi";
import ProfilePage from "@/pages/ProfilePage";
import KendaraanDerekHarianPage from "@/pages/KendaraanDerekHarianPage";
import KendaraaanDerekLaporanPage from "@/pages/KendaraanDerekLaporanPage";
import ListKendaraanRescuePage from "@/pages/ListKendaraanRescuePage";
import KendaraanRescueHarianPage from "@/pages/KendaraanRescueHarianPage";
import KendaraaanRescueLaporanPage from "@/pages/KendaraanRescueLaporanPage";
import ListKendaraanAmbulansPage from "@/pages/ListKendaraanAmbulansPage";
import ListPatroliJalanTolPage from "@/pages/ListPatroliJalanTolPage";
import ListPatroliJalanRayaPage from "@/pages/ListPatroliJalanRayaPage";
import ListKecepatanTempuhPage from "@/pages/ListKecepatanTempuhPage";
import DetailPd02Page from "@/pages/DetailPd02Page";
import AmbulansHarianPage from "@/pages/AmbulansHarianPage";
import AmbulansLaporanPage from "@/pages/AmbulansLaporanPage";
import PJTLaporanPage from "@/pages/PJTLaporanPage";
import PJTHarianPage from "@/pages/PJTHarianPage";
import PJRLaporanPage from "@/pages/PJRLaporanPage";
import LayananTransaksiPage from "@/pages/LayananTransaksiPage";
import LayananTransaksiHistoryPage from "@/pages/LayananTransaksiHistoryPage";
import AturTarifPage from "@/pages/AturTarifPage";
import ListAsetDihapusPage from "@/pages/ListAsetDihapusPage";
import AdminPage from "@/pages/AdminDashboardPage";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "Home",
    component: MainPage,
  },
  {
    path: "/lalu-lintas/kecelakaan",
    name: "Kecelakaan",
    component: KecelakaanPage,
  },
  {
    path: "/lalu-lintas",
    name: "Lalu Lintas",
    component: TrafficDashboardPage,
  },
  {
    path: "/aset",
    name: "Aset",
    component: AssetDashboardPage,
  },
  {
    path: "/aset/create",
    name: "Create Aset",
    component: CreateAssetPage,
  },
  {
    path: "/lalu-lintas/kejadian-khusus",
    name: "Kejadian Khusus",
    component: KejadianPage,
  },
  {
    path: "/lalu-lintas/kejadian-khusus/:id",
    name: "Detail Kejadian Khusus",
    component: DetailKejadianPage,
  },
  {
    path: "/lalu-lintas/gangguan-aset",
    name: "Gangguan Aset",
    component: GangguanAsetPage,
  },
  {
    path: "/lalu-lintas/gangguan-aset/:id",
    name: "Detail Gangguan Aset",
    component: DetailGangguanAsetPage,
  },
  {
    path: "/lalu-lintas/gangguan-lalu-lintas",
    name: "Gangguan Lalu Lintas",
    component: GangguanLaluLintasPage,
  },
  {
    path: "/lalu-lintas/gangguan-lalu-lintas/:id",
    name: "Detail Gangguan Lalu Lintas",
    component: DetailGangguanLaluLintasPage,
  },
  {
    path: "/aset/laporan-aset",
    name: "Laporan Aset",
    component: LaporanAsetPage,
  },
  {
    path: "/aset/tanah",
    name: "List Aset Tanah",
    component: ListAsetTanahPage,
  },
  {
    path: "/aset/tanah/riwayat",
    name: "Riwayat Aset Tanah",
    component: RiwayatAsetTanahPage,
  },
  {
    path: "/aset/tanah/:id",
    name: "Detail Aset Tanah",
    component: AsetTanahDetail,
  },
  {
    path: "/aset/pemeliharaan-aset",
    name: "Pemeliharaan Aset",
    component: PemeliharaanAsetPage,
  },
  {
    path: "/aset/pemeliharaan-aset/inisiasi-laporan/:id",
    name: "Inisiasi Laporan",
    component: InisiasiLaporanPage,
  },
  {
    path: "/aset/aset-dihibahkan",
    name: "Aset Dihibahkan",
    component: ListAsetDihibahkanPage,
  },
  {
    path: "/aset/aset-dimusnahkan",
    name: "Aset Dimusnahkan",
    component: ListAsetDimusnahkanPage,
  },
  {
    path: "/aset/aset-dilelang",
    name: "Aset Dilelang",
    component: ListAsetDilelangPage,
  },
  {
    path: "/aset/aset-diputihkan",
    name: "Aset Diputihkan",
    component: ListAsetDiputihkanPage,
  },
  {
    path: "/aset/aset-kerjasama",
    name: "Aset Kerjasama",
    component: ListAsetKerjasamaPage,
  },
  {
    path: "/aset/aset-ditingkatkan",
    name: "Aset Ditingkatkan",
    component: ListAsetDitingkatkanPage,
  },
  {
    path: "/aset/aset-dihapus",
    name: "Aset Dihapus",
    component: ListAsetDihapusPage,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/layanan-pemeliharaan",
    name: "Layanan Pemeliharaan",
    component: LayananPemeliharaanPage,
  },
  {
    path: "/lalu-lintas/kecelakaan/:id",
    name: "DetailKecelakaan",
    component: DetailKecelakaanPage,
  },
  {
    path: "/layanan-pemeliharaan/inisiasi/:id",
    name: "DetailLaporanInisiasi",
    component: DetailLaporanInisiasi,
  },
  {
    path: "/layanan-pemeliharaan/final/:id",
    name: "DetailLaporanFinal",
    component: DetailLaporanFinal,
  },
  {
    path: "/layanan-pemeliharaan/tindak-lanjut/:id",
    name: "DetailLaporanTindakLanjut",
    component: DetailLaporanTindakLanjut,
  },
  {
    path: "/aset/gedung-bangunan",
    name: "Gedung Bangunan",
    component: GedungBangunanPage,
  },
  {
    path: "/aset/gedung-bangunan/riwayat",
    name: "Riwayat Gedung Bangunan",
    component: RiwayatGedungBangunanPage,
  },
  {
    path: "/aset/gedung-bangunan/:id",
    name: "Detail Gedung Bangunan",
    component: DetailGedungBangunanPage,
  },
  {
    path: "/aset/jalan-irigasi-jaringan",
    name: "Jalan Irigasi Jaringan",
    component: JalanIrigasiJaringanPage,
  },
  {
    path: "/aset/jalan-irigasi-jaringan/riwayat",
    name: "Riwayat Jalan Irigasi Jaringan",
    component: RiwayatJalanIrigasiJaringanPage,
  },
  {
    path: "/aset/jalan-irigasi-jaringan/:id",
    name: "Detail Jalan Irigasi Jaringan",
    component: DetailJalanIrigasiJaringanPage,
  },
  {
    path: "/aset/aset-tak-berwujud",
    name: "Aset Tak Berwujud",
    component: AsetTakBerwujudPage,
  },
  {
    path: "/aset/aset-tak-berwujud/riwayat",
    name: "Riwayat Aset Tak Berwujud",
    component: RiwayatAsetTakBerwujudPage,
  },
  {
    path: "/aset/aset-tak-berwujud/:id",
    name: "Detail Aset Tak Berwujud",
    component: DetailAsetTakBerwujudPage,
  },
  {
    path: "/aset/laporan-aset/rekategorisasi/:id",
    name: "Kejadian",
    component: RekategorisasiAset,
  },
  {
    path: "/aset/mesin-peralatan",
    name: "Mesin Peralatan",
    component: MesinDanPeralatanPage,
  },
  {
    path: "/aset/mesin-peralatan/riwayat",
    name: "Riwayat Mesin dan Peralatan",
    component: RiwayatMesinDanPeralatanPage,
  },
  {
    path: "/aset/mesin-peralatan/:id",
    name: "Detail Mesin Peralatan",
    component: DetailMesinDanPeralatanPage,
  },
  {
    path: "/lalu-lintas/derek",
    name: "List Kendaraan Derek",
    component: ListKendaraanDerekPage,
  },
  {
    path: "/lalu-lintas/derek/harian",
    name: "Detail Kendaraan Derek Harian",
    component: KendaraanDerekHarianPage,
  },
  {
    path: "/lalu-lintas/derek/laporan/:id",
    name: "Detail Kendaraan Derek Laporan",
    component: KendaraaanDerekLaporanPage,
  },
  {
    path: "/lalu-lintas/derek/pd02/:id",
    name: "Detail PD02",
    component: DetailPd02Page,
  },
  {
    path: "/lalu-lintas/rescue",
    name: "List Kendaraan Rescue",
    component: ListKendaraanRescuePage,
  },
  {
    path: "/lalu-lintas/rescue/harian",
    name: "Detail Kendaraan Rescue Harian",
    component: KendaraanRescueHarianPage,
  },
  {
    path: "/lalu-lintas/rescue/laporan/:id",
    name: "Detail Kendaraan Rescue Laporan",
    component: KendaraaanRescueLaporanPage,
  },
  {
    path: "/lalu-lintas/ambulans",
    name: "List Kendaraan Ambulans",
    component: ListKendaraanAmbulansPage,
  },
  {
    path: "/lalu-lintas/ambulans/harian",
    name: "Detail Ambulans Harian",
    component: AmbulansHarianPage,
  },
  {
    path: "/lalu-lintas/ambulans/laporan/:id",
    name: "Detail Ambulans Laporan",
    component: AmbulansLaporanPage,
  },
  {
    path: "/lalu-lintas/patroli-jalan-tol",
    name: "List Patroli Jalan Tol",
    component: ListPatroliJalanTolPage,
  },
  {
    path: "/lalu-lintas/patroli-jalan-tol/harian",
    name: "Detail PJT Harian",
    component: PJTHarianPage,
  },
  {
    path: "/lalu-lintas/patroli-jalan-tol/laporan/:id",
    name: "Detail PJT Laporan",
    component: PJTLaporanPage,
  },
  {
    path: "/lalu-lintas/patroli-jalan-raya",
    name: "List Patroli Jalan Raya",
    component: ListPatroliJalanRayaPage,
  },
  {
    path: "/lalu-lintas/patroli-jalan-raya/laporan/:id",
    name: "Detail PJR Laporan",
    component: PJRLaporanPage,
  },
  {
    path: "/lalu-lintas/kecepatan-tempuh",
    name: "List Kecepatan Tempuh",
    component: ListKecepatanTempuhPage,
  },
  {
    path: "/layanan-transaksi",
    name: "Layanan Transaksi",
    component: LayananTransaksiPage,
  },
  {
    path: "/layanan-transaksi/atur-tarif",
    name: "Atur Tarif",
    component: AturTarifPage,
  },
  {
    path: "/layanan-transaksi/history",
    name: "Atur Tarif",
    component: LayananTransaksiHistoryPage,
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfilePage,
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminPage,
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
