<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        isEven: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        scrollLeft() {
            const container = this.$refs.scrollContainer.$el.querySelector('.v-data-table__wrapper');
            if (container) {
                container.scrollBy({
                    left: -200,
                    behavior: 'smooth'
                });
            }
        },
        scrollRight() {
            const container = this.$refs.scrollContainer.$el.querySelector('.v-data-table__wrapper');
            if (container) {
                container.scrollBy({
                    left: 200,
                    behavior: 'smooth'
                });
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.getDate().toString();
        },
        calculateTotal() {
            return this.data.data.reduce((sum, item) => sum + item.total, 0);
        }
    },
    computed: {
        formattedTotal() {
            return this.data.total.toLocaleString();
        },
        formattedAverage() {
            return Number(this.data.average).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        }
    }
}
</script>

<template>
    <div class="mb-8">
        <h3 class="text--black pb-3" style="font-size: 18px; font-weight: 500">{{ data.gate_name }}</h3>
        <div class="d-flex justify-space-between" justify="center">
            <v-simple-table ref="scrollContainer" style="overflow-x: auto; width: 100%;">
                <template v-slot:default>
                    <thead>
                        <tr :class="isEven ? 'mint-background' : 'mint-background'">
                            <th class="text-center" v-for="(item, index) in data.data" :key="`${data.gate_code}-header-${index}`">
                                {{ formatDate(item.date) }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="table-cell text-center" v-for="(item, index) in data.data" :key="`${data.gate_code}-value-${index}`">
                                {{ item.total.toLocaleString() }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <div class="px-8" style="position: relative; z-index: 10;">
                <v-btn class="rounded-lg"
                    style="background-color: white; position: absolute; right: 0px; top: 0%; border: 1px solid #004643; height: 45px; width: 45px; padding-left: 10px; padding-right: 10px;"
                    icon @click="scrollRight">
                    <v-icon style="color: #004643;">mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn class="rounded-lg"
                    style="background-color: white; position: absolute; right: 0px; bottom: 0%; border: 1px solid #004643; height: 45px; width: 45px; padding-left: 10px; padding-right: 10px;"
                    icon @click="scrollLeft">
                    <v-icon style="color: #004643;">mdi-chevron-left</v-icon>
                </v-btn>
            </div>
        </div>
        <div class="d-flex px-3 py-1">
            <div class="mr-8 d-flex">
                <h6 class="mr-4 text-uppercase" style="font-size: 14px; font-weight: 500; color: #A3A3A3">Jumlah</h6>
                <h6 class="text--black" style="font-size: 16px; font-weight: 500;">{{ formattedTotal }}</h6>
            </div>
            <div class="d-flex">
                <h6 class="mr-4 text-uppercase" style="font-size: 14px; font-weight: 500; color: #A3A3A3">Rerata</h6>
                <h6 class="text--black" style="font-size: 16px; font-weight: 500;">{{ formattedAverage }}</h6>
            </div>
        </div>
    </div>
</template>

<style scoped>
.table-cell {
    border-right: 1.5px solid #E8E4E6;
}

tr td:first-child {
    border-right: 1.5px solid #E8E4E6;
}

.table-cell:last-child {
    border-right: none;
}

.mint-background {
    background-color: #ABD1C6 !important;
}
</style>