<script>
import TitleMenuComponent from '@/components/TitleMenuComponent';
import PendapatanComponent from '@/sections/LayananTransaksi/PendapatanSection';
import LHRTertimbangComponent from '@/sections/LayananTransaksi/LHRTertimbangSection';
import LHRPersegmenComponent from '@/sections/LayananTransaksi/LHRPersegmenSection';
import VolLaluLintasComponent from '@/sections/LayananTransaksi/VolLaluLintasSection';
import HPTComponent from '@/sections/LayananTransaksi/HPTSection';

export default {
  components: {
    TitleMenuComponent,
    PendapatanComponent,
    LHRTertimbangComponent,
    LHRPersegmenComponent,
    VolLaluLintasComponent,
    HPTComponent
  },
  data() {
    return {
      currentButton: 0, // Default button yang aktif
      buttons: [
        { label: "Pendapatan", icon: "mdi-wallet", component: PendapatanComponent },
        { label: "LHR Tertimbang", icon: "mdi-scale", component: LHRTertimbangComponent },
        { label: "LHR Persegmen", icon: "mdi-chart-line", component: LHRPersegmenComponent },
        { label: "Vol Lalu Lintas", icon: "mdi-traffic-light", component: VolLaluLintasComponent },
        { label: "HPT", icon: "mdi-road", component: HPTComponent },
      ],
    };
  },
  computed: {
    activeComponent() {
      return this.buttons[this.currentButton].component;
    },
  },
  methods: {
    setActiveButton(index) {
      this.currentButton = index;
    },
  },
}
</script>

<template>
  <div class="pa-0 ma-0">
    <div class="px-10 py-5">
      <TitleMenuComponent title="Layanan Transaksi" />
    </div>
    <v-divider></v-divider>
    <div class="my-4 px-10">
      <v-row no-gutters>
        <v-col align="left" class="my-auto" cols="9">
          <v-sheet>
            <v-btn v-for="(button, index) in buttons" :key="index" :class="{
              'text-capitalize text-body-2 rounded-xl': true,
              'white--text': currentButton === index,
              'black--text': currentButton !== index
            }" :style="{ backgroundColor: currentButton === index ? '#004643' : '#E4EFEC' }" class="mr-4" depressed
              variant="outlined" @click="setActiveButton(index)">
              <v-icon left>{{ button.icon }}</v-icon>
              <span :class="{ 'white--text': currentButton === index, 'black--text': currentButton !== index }">
                {{ button.label }}
              </span>
            </v-btn>
          </v-sheet>
        </v-col>
        <v-col align="right" class="my-auto" cols="3">
          <v-sheet>
            <v-btn
              class="mr-4 text-capitalize text-body-2 text--black white rounded-xl"
              style=""
              variant="outlined"
              elevation="1"
              to="/layanan-transaksi/history"
            >
              <span class="text--black">History</span>
              <v-icon right>mdi-history</v-icon>
            </v-btn>
            <v-btn class="text-capitalize text-body-2 white--text rounded-xl" style="background-color: #004643;" depressed variant="outlined">
              <span class="white--text">Download</span>
              <v-icon right>mdi-download</v-icon>
            </v-btn>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <!-- <div>
      Hallo {{ buttons[currentButton].label }}
    </div> -->
    <!-- <div>
      <PendapatanComponent v-if="currentButton === 0" />
      <LHRTertimbangComponent v-else-if="currentButton === 1" />
      <LHRPersegmenComponent v-else-if="currentButton === 2" />
      <VolLaluLintasComponent v-else-if="currentButton === 3" />
      <HPTComponent v-else-if="currentButton === 4" />
    </div> -->
    <div>
      <component :is="activeComponent"></component>
    </div>
  </div>
</template>
