var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"px-10 py-5"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('div',{staticClass:"d-flex align-center justify-space-between mb-6"},[_c('h3',{staticClass:"text--black text-uppercase",staticStyle:{"font-size":"18px","font-weight":"500"}},[_vm._v("GRAFIK LHR TERTIMBANG ")]),_c('div',{staticClass:"d-flex"},[_c('v-select',{staticClass:"mr-4 rounded-lg",attrs:{"outlined":"","multiple":"","hide-details":"","items":_setup.golonganItems,"item-title":"title","item-value":"value","label":"Golongan","menu-props":{
                        location: 'bottom',
                        origin: 'top center',
                        transition: 'slide-y-transition',
                        offsetY: true,
                    }},on:{"update:model-value":_setup.updateChartData},scopedSlots:_vm._u([{key:"selection",fn:function({ index }){return [(index < 1)?_c('span',{staticClass:"mr-3"},[_vm._v("Golongan")]):_vm._e(),(index === 0)?_c('div',{staticClass:"circle-chip",style:({ fontSize: '14px', border: '1px solid #E16162', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' })},[_vm._v(" "+_vm._s(_setup.selectedGolongan.length)+" ")]):_vm._e()]}},{key:"item",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.title))])]}}]),model:{value:(_setup.selectedGolongan),callback:function ($$v) {_setup.selectedGolongan=$$v},expression:"selectedGolongan"}}),_c('v-select',{staticClass:"rounded-lg",attrs:{"outlined":"","items":_setup.periodItems,"label":"Periode","hide-details":"","menu-props":{
                        location: 'bottom',
                        origin: 'top center',
                        transition: 'slide-y-transition',
                        offsetY: true,
                    }},on:{"update:model-value":_setup.updateChartData},model:{value:(_setup.selectedPeriod),callback:function ($$v) {_setup.selectedPeriod=$$v},expression:"selectedPeriod"}})],1)]),_c('div',{staticClass:"py-6 chart-container",staticStyle:{"position":"relative","height":"400px"}},[_c('canvas',{ref:"trafficChart"})]),_c('div',{staticClass:"d-flex justify-center mt-4"},_vm._l((_setup.golonganItems.filter(item => _setup.selectedGolongan.includes(item.value))),function(item){return _c('div',{key:item.value,staticClass:"mx-3 d-flex align-center"},[_c('div',{staticClass:"mr-1",staticStyle:{"width":"16px","height":"16px"},style:({ backgroundColor: _setup.descriptionColors[item.value] })}),_c('span',{staticStyle:{"font-size":"12px","font-weight":"300"}},[_vm._v(_vm._s(item.title))])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }