<template>
  <div class="py-4 px-10">
    <v-card class="rounded-xl" outlined>
      <!-- <h3 style="font-size: 15px; font-weight: 500;">Document</h3> -->

      <v-list>
        <v-list-item
          v-for="(item, index) in documents"
          :key="index"
          class="py-4"
        >
          <v-list-item-icon>
            <v-icon>mdi-clock-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ item.date }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action class="document-links">
            <div v-for="(file, fileIndex) in item.files" :key="fileIndex">
              <a
                @click.prevent="downloadFile(file.url)"
                class="text-decoration-none blue--text text--darken-1"
                href="#"
              >
                {{ file.name }}
              </a>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DocumentList",

  data: () => ({
    documents: [
      {
        date: "Friday, 24 Oct 2024 • 12.44",
        files: [
          {
            name: "att6-narogong-01-10-2024.xls",
            url: "/dummy/att6-narogong-01-10-2024.xls",
          },
          {
            name: "att5-narogong-01-10-2024.xls",
            url: "/dummy/att5-narogong-01-10-2024.xls",
          },
          {
            name: "atb6-jafikarya1-01-10-2024.xls",
            url: "/dummy/atb6-jafikarya1-01-10-2024.xls",
          },
        ],
      },
      {
        date: "Friday, 24 Oct 2024 • 12.44",
        files: [
          {
            name: "att6-narogong-01-10-2024.xls",
            url: "/dummy/att6-narogong-01-10-2024.xls",
          },
          {
            name: "att5-narogong-01-10-2024.xls",
            url: "/dummy/att5-narogong-01-10-2024.xls",
          },
          {
            name: "atb6-jafikarya1-01-10-2024.xls",
            url: "/dummy/atb6-jafikarya1-01-10-2024.xls",
          },
        ],
      },
      {
        date: "Friday, 24 Oct 2024 • 12.44",
        files: [
          {
            name: "att6-narogong-01-10-2024.xls",
            url: "/dummy/att6-narogong-01-10-2024.xls",
          },
          {
            name: "att5-narogong-01-10-2024.xls",
            url: "/dummy/att5-narogong-01-10-2024.xls",
          },
          {
            name: "atb6-jafikarya1-01-10-2024.xls",
            url: "/dummy/atb6-jafikarya1-01-10-2024.xls",
          },
        ],
      },
    ],
  }),

  methods: {
    downloadFile(url) {
      // In a real application, you would handle the actual file download here
      // This is just a simulation
      console.log("Downloading file from:", url);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "");
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
.document-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.document-links a:hover {
  text-decoration: underline !important;
}
</style>
