import { render, staticRenderFns } from "./TableStyleComponentTotal.vue?vue&type=template&id=e4ed8a58&scoped=true"
import script from "./TableStyleComponentTotal.vue?vue&type=script&lang=js"
export * from "./TableStyleComponentTotal.vue?vue&type=script&lang=js"
import style0 from "./TableStyleComponentTotal.vue?vue&type=style&index=0&id=e4ed8a58&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4ed8a58",
  null
  
)

export default component.exports