<script>
export default {
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    methods: {
        scrollLeft() {
            const container = this.$refs.scrollContainer.$el.querySelector('.v-data-table__wrapper');
            if (container) {
                container.scrollBy({
                    left: -200,
                    behavior: 'smooth'
                });
            }
        },
        scrollRight() {
            const container = this.$refs.scrollContainer.$el.querySelector('.v-data-table__wrapper');
            if (container) {
                container.scrollBy({
                    left: 200,
                    behavior: 'smooth'
                });
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.getDate().toString();
        },
        calculateTotal() {
            return this.data.reduce((sum, item) => sum + item.total, 0);
        },
        formatPercentage(value) {
            if (!value) {
                return '0%';
            }
            return `${value}%`;
        },
    },
}
</script>

<template>
    <div class="mb-8">
        <div class="d-flex justify-space-between">
            <v-simple-table ref="scrollContainer" style="overflow-x: auto; width: 100%;">
                <template v-slot:default>
                    <thead>
                        <tr style="background-color: #F9BC60;">
                            <th class="text-center">#</th>
                            <th class="text-center" v-for="(item, index) in data" :key="`header-${index}`">
                                {{ formatDate(item.record_date) }}
                            </th>
                            <!-- <th class="text-center">Jumlah</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center">TVL</td>
                            <td v-for="(item, index) in data" :key="`sum-${index}`" class="table-cell text-center">
                                {{ item.total.toLocaleString() }}
                            </td>
                            <!-- <td class="text-center">{{ calculateTotal().toLocaleString() }}</td> -->
                        </tr>
                        <tr>
                            <td class="text-center">%</td>
                            <td v-for="(item, index) in data" :key="`sum-${index}`" class="table-cell text-center">
                                {{ formatPercentage(item.percentage) }}
                            </td>
                            <!-- <td class="text-center">{{ calculateTotal().toLocaleString() }}</td> -->
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <div class="d-flex flex-column justify-center items-center pl-4">
                <v-btn
                    class="rounded-lg pa-6"
                    style="border: 1px solid #004643;"
                    icon
                    @click="scrollRight"
                >
                    <v-icon color="#004643">mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn
                    class="rounded-lg mt-2 pa-6"
                    style="border: 1px solid #004643;"
                    icon
                    @click="scrollLeft"
                >
                    <v-icon color="#004643">mdi-chevron-left</v-icon>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<style scoped>
.table-cell {
    border-right: 1.5px solid #E8E4E6;
}

tr td:first-child {
    border-right: 1.5px solid #E8E4E6;
}

.table-cell:last-child {
    border-right: none;
}

/* Add this to ensure the table wrapper is scrollable */
:deep(.v-data-table__wrapper) {
    overflow-x: auto;
    scroll-behavior: smooth;
}
</style>