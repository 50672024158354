<template>
  <div class="pa-0 ma-0">
    <div class="px-10 py-5">
      <TitleMenuComponent title="History" />
    </div>
    <v-divider></v-divider>
    <div class="pb-3 pt-4 px-10">
      <v-row no-gutters>
        <v-col align="left" class="my-auto" cols="7">
          <v-sheet>
            <v-btn
              class="text-capitalize text-body-1 rounded-lg"
              style="border: 1px solid #ABD1C6; color: black"
              depressed
              variant="outlined"
              @click="$router.back()"
            >
              <v-icon left>mdi-chevron-left</v-icon>
              <span>Back</span>
            </v-btn>
          </v-sheet>
        </v-col>
        <v-col align="right" class="my-auto" cols="5">
          <v-sheet>
            <v-row no-gutters>
              <v-col align="left" class="my-auto" cols="2">
                <v-sheet>
                  <span
                    class="text--black text-uppercase"
                    style="font-size: 12px; font-weight: 400; color: #bbbbbb"
                    >Filter</span
                  >
                  <h6
                    class="text--black"
                    style="font-size: 14px; font-weight: 400"
                  >
                    Pilih Tanggal
                  </h6>
                </v-sheet>
              </v-col>
              <v-col align="right" class="my-auto" cols="10">
                <v-sheet>
                  <DateFilterComponent
                    @update:startDate="updateStartDate"
                    @update:endDate="updateEndDate"
                    @update:formattedDateRange="updateFormattedDateRange"
                  />
                </v-sheet>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
    <HistoryComponent />
  </div>
</template>

<script>
import TitleMenuComponent from "@/components/TitleMenuComponent";
import DateFilterComponent from "@/components/LayananTransaksi/DateFilterComponent.vue";
import HistoryComponent from "@/components/LayananTransaksi/History/HistoryComponent.vue";

export default {
  components: {
    DateFilterComponent,
    TitleMenuComponent,
    HistoryComponent,
  },
  data() {
    return {
      start_date: "",
      end_date: "",
      formatted_date_range: "",
    };
  },
  methods: {
    updateStartDate(newDate) {
      this.start_date = newDate;
    },
    updateEndDate(newDate) {
      this.end_date = newDate;
    },
    updateFormattedDateRange(newRange) {
      this.formatted_date_range = newRange;
    },
  },
};
</script>
