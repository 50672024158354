<script>
export default {
    data() {
        return {
            revenueData: [
                { name: "Jatikarya 1", amount: "Rp. 999.000.000", color: "orange" },
                { name: "Jatikarya 2", amount: "Rp. 999.000.000", color: "red" },
                { name: "Jatikarya U 1", amount: "Rp. 999.000.000", color: "teal" },
                { name: "Jatikarya U 2", amount: "Rp. 999.000.000", color: "light-green" },
                { name: "Nagrak", amount: "Rp. 999.000.000", color: "purple" },
            ],
            legends: [
                { name: "Off Ramp", color: "orange" },
                { name: "Entrance", color: "teal" },
                { name: "Exit", color: "red" },
            ],
        };
    },
    methods: {
        scrollLeft() {
            const container = this.$refs.scrollContainer;
            if (container) {
                container.scrollLeft -= 200;
                container.behavior = 'smooth';
            }
        },
        scrollRight() {
            const container = this.$refs.scrollContainer;
            if (container) {
                container.scrollLeft += 200;
                container.behavior = 'smooth';
            }
        },
    },
}
</script>

<template>
    <div class="py-4 px-10">
        <v-card class="rounded-xl" outlined>
            <v-row no-gutters class="pa-5 text-center">
                <v-col class="text-uppercase my-auto">
                    <h3 style="font-size: 14px; font-weight: 600;">Jumlah LHR Tertimbang</h3>
                    <h1 style="font-size: 42px; font-weight: 500;">145.880</h1>
                    <h3 style="font-size: 16; font-weight: 400; color: #A3A3A3;">Kendaraan</h3>
                </v-col>
                <v-col class="text-uppercase my-auto">
                    <h3 style="font-size: 14px; font-weight: 600;">LHR Tertimbang Rerata</h3>
                    <h1 style="font-size: 42px; font-weight: 500;">35.747</h1>
                    <h3 style="font-size: 16; font-weight: 400; color: #A3A3A3;">Kendaraan</h3>
                </v-col>
                <v-col>
                    <div class="rounded-xl pa-5" style="border: 1.5px solid #E8E4E6;">
                        <v-row no-gutters class="mb-3">
                            <v-col class="mr-2 text-uppercase my-auto">
                                <h3 style="font-size: 14px; font-weight: 600;">RKAP (2024)</h3>
                                <h1 style="font-size: 42px; font-weight: 500;">31.551</h1>
                                <h3 style="font-size: 12px; font-weight: 400; color: #A3A3A3;">Kendaraan</h3>
                            </v-col>
                            <v-col class="ml-2 text-uppercase my-auto">
                                <h3 style="font-size: 14px; font-weight: 600;">PPJT (2024)</h3>
                                <h1 style="font-size: 42px; font-weight: 500;">38.669</h1>
                                <h3 style="font-size: 12px; font-weight: 400; color: #A3A3A3;">Kendaraan</h3>
                            </v-col>
                        </v-row>
                        <div class="rounded-lg text-uppercase white--text py-3 p"
                            style="background-color: #004643; cursor: pointer;">
                            Update
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row no-gutters style="overflow: hidden;">
                <v-col v-for="(data, index) in revenueData" :key="index" class="pa-5 custom-border"
                    :class="{ 'last-child': index === revenueData.length - 1 }">
                    <div class="d-flex align-center">
                        <v-avatar class="mr-3" size="10" :color="data.color"></v-avatar>
                        <div class="text-uppercase">
                            <h5 style="font-size: 12px; font-weight: 600;">Golongan {{ index + 1 }}</h5>
                            <h5 class="pr-3" style="font-size: 20px; font-weight: 500;">
                                {{ data.amount }}
                            </h5>
                            <h5 style="font-size: 12px; font-weight: 400; color: #A3A3A3;">Kendaraan</h5>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<style scoped>
.custom-border {
    border-top: 1px solid #E8E4E6;
    border-right: 1px solid #E8E4E6;
}

.custom-border.last-child {
    border-right: none;
}
</style>