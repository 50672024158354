<script>
export default {
    data() {
        return {
            revenueData: [
                { name: "BCA", amount: "Rp. 999.000.000", color: "#ABB5D1" },
                { name: "BNI", amount: "Rp. 999.000.000", color: "#D1B7AB" },
                { name: "BRI", amount: "Rp. 999.000.000", color: "#D1CEAB" },
                { name: "Mandiri", amount: "Rp. 999.000.000", color: "#D1ABD1" },
                { name: "Tunai", amount: "Rp. 999.000.000", color: "#ABD1C6" },
            ],
            legends: [
                { name: "Off Ramp", color: "orange" },
                { name: "Entrance", color: "teal" },
                { name: "Exit", color: "red" },
            ],
        };
    },
    methods: {
        scrollLeft() {
            const container = this.$refs.scrollContainer;
            if (container) {
                container.scrollLeft -= 200;
                container.behavior = 'smooth';
            }
        },
        scrollRight() {
            const container = this.$refs.scrollContainer;
            if (container) {
                container.scrollLeft += 200;
                container.behavior = 'smooth';
            }
        },
    },
}
</script>

<template>
    <div class="py-4 px-10">
        <v-card class="rounded-xl" outlined>
            <v-row no-gutters class="pa-5 text-center">
                <v-col class="text-uppercase my-auto">
                    <h3 style="font-size: 14px; font-weight: 600;">HPT</h3>
                    <h1 style="font-size: 42px; font-weight: 500;">999.000.000</h1>
                </v-col>
            </v-row>

            <v-row no-gutters style="overflow: hidden;">
                <v-col v-for="(data, index) in revenueData" :key="index" class="pa-5 custom-border"
                    :class="{ 'last-child': index === revenueData.length - 1 }">
                    <div class="d-flex align-center">
                        <v-avatar class="mr-3" size="10" :color="data.color"></v-avatar>
                        <div class="text-uppercase">
                            <h5 style="font-size: 12px; font-weight: 600;">{{ data.name }}</h5>
                            <h5 class="pr-3" style="font-size: 20px; font-weight: 500;">
                                {{ data.amount }}
                            </h5>
                            <h5 style="font-size: 12px; font-weight: 400; color: #A3A3A3;">Kendaraan</h5>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<style scoped>
.custom-border {
    border-top: 1px solid #E8E4E6;
    border-right: 1px solid #E8E4E6;
}

.custom-border.last-child {
    border-right: none;
}
</style>