<template>
  <div class="px-10 py-5">
    <v-card elevation="0">
      <div class="d-flex align-center justify-space-between mb-6">
        <h3 class="text--black text-uppercase" style="font-size: 18px; font-weight: 500">Grafik Pendapatan</h3>
        <div class="d-flex">
          <v-select
            outlined
            multiple
            hide-details
            v-model="selectedGate"
            :items="gateItems"
            item-text="name"
            item-value="code"
            label="Gerbang Tol"
            class="mr-4 rounded-lg"
            :menu-props="{
              location: 'bottom',
              origin: 'top center',
              transition: 'slide-y-transition',
              offsetY: true,
            }"
          >
            <template v-slot:selection="{ index }">
              <span class="mr-3" style="font-size: 14px" v-if="index < 1">Gerbang Tol</span>
              <div v-if="index === 0" 
                  class="circle-chip" 
                  :style="{ fontSize: '14px', border: '1px solid #E16162', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
                {{ selectedGate.length }}
              </div>

            </template>
            <template v-slot:item="{ item }">
              <span style="font-size: 14px">{{ item.name }}</span>
            </template>
          </v-select>

          <v-select
            outlined
            v-model="selectedPeriod"
            :items="periodItems"
            item-text="title"
            item-value="value"
            label="Periode"
            hide-details
            class="rounded-lg"
            :menu-props="{
              location: 'bottom',
              origin: 'top center',
              transition: 'slide-y-transition',
              offsetY: true,
            }"
          >
            <template v-slot:selection="{ item }">
              <span style="font-size: 14px">{{ item.title }}</span>
            </template>
            <template v-slot:item="{ item }">
              <span style="font-size: 14px">{{ item.title }}</span>
            </template>
          </v-select>
        </div>
      </div>

      <!-- Chart Container -->
      <div class="py-6 chart-container" style="position: relative; height: 400px;">
        <canvas ref="revenueChart"></canvas>
      </div>
    </v-card>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      gateItems: [],
      selectedGate: [],
      periodItems: [
        { title: 'Harian', value: 'daily' },
        { title: 'Mingguan', value: 'weekly' },
        { title: 'Bulanan', value: 'monthly' },
      ],
      selectedPeriod: 'daily',
      chart: null
    }
  },
  computed: {
    processedData() {
      if (!this.data || !this.data.length) return null;
      
      return {
        labels: this.data.map(item => {
          const date = new Date(item.date);
          const monthName = date.toLocaleString('default', { month: 'short' });
          return `${date.getDate()} ${monthName}`;
        }),
        datasets: [{
          label: 'Total Pendapatan',
          data: this.data.map(item => Number(item.total)),
          backgroundColor: '#ABD1C6',
          borderColor: '#ABD1C6',
          borderWidth: 1,
        }]
      };
    }
  },
  watch: {
    processedData: {
      handler(newData) {
        if (newData && this.chart) {
          this.chart.data = newData;
          this.chart.update();
        }
      },
      deep: true
    },
    selectedPeriod() {
      this.emitDateUpdates();
    },
    selectedGate() {
      this.emitDateUpdates();
    },
  },
  async mounted() {
    await this.fetchDataGate();
    this.selectedGate = this.gateItems.map(item => item.code);
    this.initChart();
    this.emitDateUpdates();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    async fetchDataGate() {
      try {
        const params = {
          is_has_income: true
        }
        const response = await this.$axios.get('gates', { params });
        this.gateItems = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    emitDateUpdates() {
      this.$emit('update:selectedPeriod', this.selectedPeriod);
      this.$emit('update:selectedGate', this.selectedGate);
    },
    initChart() {
      if (this.chart) {
        this.chart.destroy();
      }

      const ctx = this.$refs.revenueChart.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: this.processedData || {
          labels: [],
          datasets: [{
            label: 'Total Pendapatan',
            data: [],
            backgroundColor: '#ABD1C6',
            borderColor: '#ABD1C6',
            borderWidth: 1,
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: true,
                color: '#f0f0f0',
              },
            },
            y: {
              grid: {
                display: true,
                color: '#f0f0f0',
              },
              ticks: {
                callback: function(value) {
                  return value.toLocaleString()
                }
              },
              suggestedMin: 0,
            }
          },
          plugins: {
            legend: {
              display: false,
            },
          }
        }
      });
    }
  }
}
</script>

<style scoped>
.v-select {
  max-width: 200px;
}

.circle-chip {
  width: 30px; 
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  background-color: #E16162;
}
</style>