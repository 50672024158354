<script>
export default {
  name: 'RouteDataTable',
  props: {
    section: {
      type: Object,
      required: true
    },
    isEven: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    scrollLeft() {
      const container = this.$refs.scrollContainer.$el.querySelector('.v-data-table__wrapper');
      if (container) {
        container.scrollBy({
          left: -200,
          behavior: 'smooth'
        });
      }
    },
    scrollRight() {
      const container = this.$refs.scrollContainer.$el.querySelector('.v-data-table__wrapper');
      if (container) {
        container.scrollBy({
          left: 200,
          behavior: 'smooth'
        });
      }
    }
  }
}
</script>

<template>
  <div class="mb-8">
    <div class="d-flex align-center mb-3">
      <h3 class="text--black" style="font-size: 20px; font-weight: 500;">{{ section.title }}</h3>
      <span class="ml-3" style="font-size: 16px; font-weight: 500; color: #E16162;">{{ section.distance }}Km</span>
    </div>
    
    <div class="d-flex justify-space-between">
      <v-simple-table ref="scrollContainer" dense class="elevation-0 route-table">
        <template v-slot:default>
          <thead>
            <tr class="mint-background">
              <th class="text-center black--text py-3 first-col" style="background-color: #ABD1C6 !important; border-right: none; font-size: 14px; font-weight: 500;">GOL</th>
              <th class="text-center black--text" style="font-size: 14px; font-weight: 500;" v-for="n in 25" :key="`header-${n}`">{{ n }}</th>
              <th class="text-center white--text last-col rerata-col" style="background-color: #004643; font-size: 14px; font-weight: 500;">RERATA</th>
              <th class="text-center white--text last-col jumlah-col" style="background-color: #004643; font-size: 14px; font-weight: 500;">JUMLAH</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in 5" :key="`row-${row}`">
              <td class="text-center black--text first-col" style="font-size: 14px; font-weight: 500;">{{ row }}</td>
              <td class="text-center black--text table-cell" style="font-size: 14px; font-weight: 500;" v-for="col in 25" :key="`cell-${row}-${col}`">
                14.802
              </td>
              <td class="text-center black--text last-col rerata-col" style="background-color: #E8E4E6 !important; font-size: 14px; font-weight: 500;">34.802</td>
              <td class="text-center black--text last-col jumlah-col" style="background-color: #E8E4E6 !important; font-size: 14px; font-weight: 500;">69.716</td>
            </tr>
            <tr class="total-row">
              <td class="text-center white--text first-col" style="background-color: #004643 !important; font-size: 14px; font-weight: 500;">TOTAL</td>
              <td class="text-center table-cell" style="background-color: #E8E4E6 !important; font-size: 14px; font-weight: 500;" v-for="n in 25" :key="`total-${n}`">
                14.802
              </td>
              <td class="text-center last-col rerata-col" style="background-color: #B2AFB1 !important; font-size: 14px; font-weight: 500;">14.802</td>
              <td class="text-center last-col jumlah-col" style="background-color: #B2AFB1 !important; font-size: 14px; font-weight: 500;">69.716</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="d-flex flex-column justify-center items-center pl-4">
        <v-btn
          class="rounded-lg pa-6"
          style="border: 1px solid #004643;"
          icon
          @click="scrollRight"
        >
          <v-icon color="#004643">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          class="rounded-lg mt-2 pa-6"
          style="border: 1px solid #004643;"
          icon
          @click="scrollLeft"
        >
          <v-icon color="#004643">mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.route-table {
  width: 100%;
  overflow-x: auto;
}

.table-cell {
  border-right: 1.5px solid #E8E4E6;
}

.first-col {
  background-color: white !important;
  position: sticky;
  left: 0;
  z-index: 2;
  border-right: 1.5px solid #E8E4E6;
}

.last-col {
  position: sticky;
  z-index: 1;
}

.rerata-col {
  right: 92px; /* Adjust this value based on the width of the JUMLAH column */
}

.jumlah-col {
  right: 0;
}

.mint-background {
  background-color: #ABD1C6 !important;
}

.total-row {
  background-color: #F5F5F5;
}

/* Override Vuetify default styles */
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: 500;
  white-space: nowrap;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 14px;
  height: 48px;
}
</style>