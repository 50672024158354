<template>
    <div class="px-10 py-5">
        <v-card elevation="0">
            <div class="d-flex align-center justify-space-between mb-6">
                <h3 class="text--black text-uppercase" style="font-size: 18px; font-weight: 500">GRAFIK LHR TERTIMBANG
                </h3>
                <div class="d-flex">
                    <v-select outlined multiple hide-details v-model="selectedGolongan" :items="golonganItems"
                        item-title="title" item-value="value" label="Golongan" class="mr-4 rounded-lg"
                        @update:model-value="updateChartData" :menu-props="{
                            location: 'bottom',
                            origin: 'top center',
                            transition: 'slide-y-transition',
                            offsetY: true,
                        }">
                        <template v-slot:selection="{ index }">
                            <span class="mr-3" v-if="index < 1">Golongan</span>
                            <div v-if="index === 0" 
                                class="circle-chip" 
                                :style="{ fontSize: '14px', border: '1px solid #E16162', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
                                {{ selectedGolongan.length }}
                            </div>
                        </template>
                        <template v-slot:item="{ item }">
                            <span>{{ item.title }}</span>
                        </template>
                    </v-select>

                    <v-select outlined v-model="selectedPeriod" :items="periodItems" label="Periode" hide-details
                        class="rounded-lg" @update:model-value="updateChartData" :menu-props="{
                            location: 'bottom',
                            origin: 'top center',
                            transition: 'slide-y-transition',
                            offsetY: true,
                        }"></v-select>
                </div>
            </div>

            <!-- Chart Container -->
            <div class="py-6 chart-container" style="position: relative; height: 400px;">
                <canvas ref="trafficChart"></canvas>
            </div>

            <!-- Description -->
            <div class="d-flex justify-center mt-4">
                <div v-for="item in golonganItems.filter(item => selectedGolongan.includes(item.value))"
                    :key="item.value" class="mx-3 d-flex align-center">
                    <div class="mr-1" style="width: 16px; height: 16px;"
                        :style="{ backgroundColor: descriptionColors[item.value] }"></div>
                    <span style="font-size: 12px; font-weight: 300;">{{ item.title }}</span>
                </div>
            </div>

            <!-- RERATA Section -->
            <!-- <div class="d-flex justify-end align-center mt-4">
                <div class="text-caption mr-2">RERATA</div>
                <div class="text-h6">{{ averageTraffic.toLocaleString() }}</div>
            </div> -->
        </v-card>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import Chart from 'chart.js/auto'

// Data
const golonganItems = [
    { title: 'Golongan 1', value: 'golongan1' },
    { title: 'Golongan 2', value: 'golongan2' },
    { title: 'Golongan 3', value: 'golongan3' },
    { title: 'Golongan 4', value: 'golongan4' },
    { title: 'Golongan 5', value: 'golongan5' }
]
const selectedGolongan = ref(golonganItems.map(item => item.value))

const periodItems = ['Bulanan', 'Mingguan', 'Harian']
const selectedPeriod = ref(periodItems[0])

const trafficChart = ref(null)
let chart = null

const descriptionColors = {
    golongan1: '#81c784',
    golongan2: '#9fa8da',
    golongan3: '#ffab91',
    golongan4: '#fff59d',
    golongan5: '#ce93d8'
}

// Generate dummy data
const generateDummyData = (count, min, max) => {
    return Array.from({ length: count }, () => Math.floor(Math.random() * (max - min + 1) + min))
}

// Chart data for different periods and golongan
const chartData = {
    Bulanan: {
        labels: Array.from({ length: 31 }, (_, i) => i + 1),
        datasets: golonganItems.map(item => ({
            label: item.title,
            data: generateDummyData(31, 10000, 40000),
            backgroundColor: descriptionColors[item.value],
            borderColor: descriptionColors[item.value],
            borderWidth: 1,
        }))
    },
    Mingguan: {
        labels: ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'],
        datasets: golonganItems.map(item => ({
            label: item.title,
            data: generateDummyData(7, 50000, 150000),
            backgroundColor: descriptionColors[item.value],
            borderColor: descriptionColors[item.value],
            borderWidth: 1,
        }))
    },
    Harian: {
        labels: Array.from({ length: 24 }, (_, i) => i),
        datasets: golonganItems.map(item => ({
            label: item.title,
            data: generateDummyData(24, 5000, 20000),
            backgroundColor: descriptionColors[item.value],
            borderColor: descriptionColors[item.value],
            borderWidth: 1,
        }))
    }
}

// Chart configuration
const getChartConfig = (period, selectedGolonganValues) => ({
    type: 'bar',
    data: {
        labels: chartData[period].labels,
        datasets: chartData[period].datasets.filter(dataset =>
            selectedGolonganValues.includes(golonganItems.find(item => item.title === dataset.label).value)
        )
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: true,
                    color: '#f0f0f0',
                },
            },
            y: {
                stacked: true,
                grid: {
                    display: true,
                    color: '#f0f0f0',
                },
                ticks: {
                    callback: function (value) {
                        return value.toLocaleString()
                    }
                },
                suggestedMin: 0,
                suggestedMax: 180000,
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: 80000,
                        yMax: 80000,
                        borderColor: 'red',
                        borderWidth: 1,
                        borderDash: [5, 5],
                    },
                    line2: {
                        type: 'line',
                        yMin: 120000,
                        yMax: 120000,
                        borderColor: 'blue',
                        borderWidth: 1,
                        borderDash: [5, 5],
                    },
                    line3: {
                        type: 'line',
                        yMin: 140000,
                        yMax: 140000,
                        borderColor: 'green',
                        borderWidth: 1,
                        borderDash: [5, 5],
                    }
                }
            }
        }
    }
})

// Calculate average traffic
const averageTraffic = ref(999999)

// Update chart data when period or golongan changes
const updateChartData = () => {
    if (chart) {
        const config = getChartConfig(selectedPeriod.value, selectedGolongan.value)
        chart.data = config.data
        chart.options = config.options
        chart.update()

        // Update average traffic (you may want to implement a more accurate calculation)
        averageTraffic.value = Math.floor(Math.random() * (1000000 - 900000) + 900000)
    }
}

// Initialize chart on component mount
onMounted(() => {
    const ctx = trafficChart.value.getContext('2d')
    chart = new Chart(ctx, getChartConfig(selectedPeriod.value, selectedGolongan.value))
})

// Watch for changes in selectedPeriod and selectedGolongan
watch([selectedPeriod, selectedGolongan], updateChartData)
</script>

<style scoped>
.v-select {
    max-width: 200px;
}

.circle-chip {
  width: 30px; 
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  background-color: #E16162;
}
</style>
